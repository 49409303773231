// Copyright 2021 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import './login.css'
import {Link} from "react-router-dom";
import {Button, Checkbox, Col, Form, Input, Result, Row, Spin} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import * as AuthBackend from "./AuthBackend";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import * as Provider from "./Provider";
import * as Util from "./Util";
import * as Setting from "../Setting";
import SelfLoginButton from "./SelfLoginButton";
import {GithubLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import FacebookLoginButton from "./FacebookLoginButton";
import QqLoginButton from "./QqLoginButton";
import DingTalkLoginButton from "./DingTalkLoginButton";
import GiteeLoginButton from "./GiteeLoginButton";
import WechatLoginButton from "./WechatLoginButton";
import WeiboLoginButton from "./WeiboLoginButton";
import i18next from "i18next";
import LinkedInLoginButton from "./LinkedInLoginButton";
import WeComLoginButton from "./WeComLoginButton";
import LarkLoginButton from "./LarkLoginButton";
import GitLabLoginButton from "./GitLabLoginButton";
import AdfsLoginButton from "./AdfsLoginButton";
import BaiduLoginButton from "./BaiduLoginButton";
import AlipayLoginButton from "./AlipayLoginButton";
import CasdoorLoginButton from "./CasdoorLoginButton";
import InfoflowLoginButton from "./InfoflowLoginButton";
import AppleLoginButton from "./AppleLoginButton"
import AzureADLoginButton from "./AzureADLoginButton";
import SlackLoginButton from "./SlackLoginButton";
import SteamLoginButton from "./SteamLoginButton";
import OktaLoginButton from "./OktaLoginButton";
import DouyinLoginButton from "./DouyinLoginButton";
import CustomGithubCorner from "../CustomGithubCorner";
import {CountDownInput} from "../common/CountDownInput";
import BilibiliLoginButton from "./BilibiliLoginButton";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: props,
            type: props.type,
            applicationName: props.applicationName !== undefined ? props.applicationName : (props.match === undefined ? null : props.match.params.applicationName),
            owner: props.owner !== undefined ? props.owner : (props.match === undefined ? null : props.match.params.owner),
            application: null,
            mode: props.mode !== undefined ? props.mode : (props.match === undefined ? null : props.match.params.mode), // "signup" or "signin"
            isCodeSignin: false,
            msg: null,
            username: null,
            validEmailOrPhone: false,
            validEmail: false,
            validPhone: false,
        };
        console.log("第一步")
        console.log( this.state)

        if (this.state.type === "cas" && props.match?.params.casApplicationName !== undefined) {
            this.state.owner = props.match?.params.owner
            this.state.applicationName = props.match?.params.casApplicationName
        }

    }

    componentDidUpdate() {
        // if (this.getCookie("bing-phone") === "00" && document.getElementsByClassName("kc")[0] !== undefined) {
        //     document.getElementsByClassName("kc")[0].click()
        // }
    }

    setCookie(cname, cvalue, exdays) {
        let time = new Date();
        time.setDate(time.getDate() + 1);
        time.setHours(0);
        time.setMinutes(0);
        time.setSeconds(0);
        // let d = new Date();
        // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        // let expires = "expires=" + d.toUTCString();
        let expires = "expires=" + time.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    UNSAFE_componentWillMount() {
        if (this.state.type === "login" || this.state.type === "cas") {
            this.getApplication();
        } else if (this.state.type === "code") {
            this.getApplicationLogin();
        } else if (this.state.type === "saml") {
            this.getSamlApplication();
        } else {
            Util.showMessage("error", `Unknown authentication type: ${this.state.type}`);
        }
    }

    getApplicationLogin() {
        console.log("1")
        const oAuthParams = Util.getOAuthGetParameters();
        AuthBackend.getApplicationLogin(oAuthParams)
            .then((res) => {
                if (res.status === "ok") {
                    this.setState({
                        application: res.data,
                    });
                } else {
                    // Util.showMessage("error", res.msg);
                    this.setState({
                        application: res.data,
                        msg: res.msg,
                    });
                }
            });
    }

    getApplication() {
        if (this.state.applicationName === null) {
            return;
        }
        ApplicationBackend.getApplication("admin", this.state.applicationName)
            .then((application) => {
                this.setState({
                    application: application,
                });
            });
    }

    getSamlApplication() {
        if (this.state.applicationName === null) {
            return;
        }
        ApplicationBackend.getApplication(this.state.owner, this.state.applicationName)
            .then((application) => {
                    this.setState({
                        application: application,
                    });
                }
            );
    }

    getApplicationObj() {
        // if (this.getCookie('IDENTITY') !== '') {
        //     if (this.props.application !== undefined) {
        //         this.onFinish1(this.props.application)
        //     }
        //     if (this.state.application !== null) {
        //         this.onFinish1(this.state.application)
        //     }
        // }
        if (this.props.application !== undefined) {
            return this.props.application;
        } else {
            return this.state.application;
        }
    }

    onUpdateAccount(account) {
        this.props.onUpdateAccount(account);
    }

    onFinish1(e) {
        const application = e;
        const ths = this;
        const oAuthParams = Util.getOAuthGetParameters();
        this.arr = this.decode(this.getCookie('IDENTITY')).split("-")
        let parameter = {
            type: this.state.type,
            phonePrefix: "86",
            organization: application.organization,
            application: application.name,
            password: this.arr[1],
            username: this.arr[0],
        }
        console.log(parameter)
        AuthBackend.login(parameter, oAuthParams).then((res) => {
            console.log('4')
            if (res.status === 'ok') {
                const responseType = this.state.type;
                if (responseType === "login") {
                    Util.showMessage("success", `Logged in successfully`);
                    console.log('5')
                    const link = Setting.getFromLink();
                    Setting.goToLink(link);
                } else if (responseType === "code") {
                    const code = res.data;
                    const concatChar = oAuthParams?.redirectUri?.includes('?') ? '&' : '?';

                    if (Setting.hasPromptPage(application)) {
                        AuthBackend.getAccount("")
                            .then((res) => {
                                let account = null;
                                if (res.status === "ok") {
                                    account = res.data;
                                    account.organization = res.data2;
                                    console.log('6')
                                    this.onUpdateAccount(account);

                                    if (Setting.isPromptAnswered(account, application)) {
                                        Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
                                    } else {
                                        Setting.goToLinkSoft(ths, `/prompt/${application.name}?redirectUri=${oAuthParams.redirectUri}&code=${code}&state=${oAuthParams.state}`);
                                    }
                                } else {
                                    Setting.showMessage("error", `Failed to sign in: ${res.msg}`);
                                }
                            });
                    } else {
                        Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
                    }

                    // Util.showMessage("success", `Authorization code: ${res.data}`);
                } else if (responseType === "token" || responseType === "id_token") {
                    const accessToken = res.data;
                    Setting.goToLink(`${oAuthParams.redirectUri}#${responseType}=${accessToken}?state=${oAuthParams.state}&token_type=bearer`);
                } else if (responseType === "saml") {
                    const SAMLResponse = res.data;
                    const redirectUri = res.data2;
                    Setting.goToLink(`${redirectUri}?SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${oAuthParams.relayState}`);
                }
            } else {
                Util.showMessage("error", `Failed to log in: ${res.msg}`);
            }
        });
    }

    onFinish(values) {
        const application = this.getApplicationObj();
        console.log(application.organization)
        const ths = this;

        //here we are supposed to judge whether casdoor is working as a oauth server or CAS server
        if (this.state.type === "cas") {
            console.log('1')
            //cas
            const casParams = Util.getCasParameters()
            values["type"] = this.state.type;

            AuthBackend.loginCas(values, casParams).then((res) => {
                console.log(values)
                if (res.status === 'ok') {
                    console.log('2')
                    let msg = "Logged in successfully. "
                    if (casParams.service === "") {
                        //If service was not specified, CAS MUST display a message notifying the client that it has successfully initiated a single sign-on session.
                        msg += "Now you can visit apps protected by casdoor."
                    }
                    Util.showMessage("success", msg);
                    if (casParams.service !== "") {
                        let st = res.data
                        window.location.href = casParams.service + "?ticket=" + st
                    }

                } else {
                    Util.showMessage("error", `Failed to log in: ${res.msg}`);
                }
            })
        } else {
            console.log('3')
            //oauth
            const oAuthParams = Util.getOAuthGetParameters();
            console.log(oAuthParams)
            if (oAuthParams !== null && oAuthParams.responseType != null && oAuthParams.responseType !== "") {
                values["type"] = oAuthParams.responseType
            } else {
                values["type"] = this.state.type;
                console.log( values["type"])
            }
            values["phonePrefix"] = this.getApplicationObj()?.organizationObj.phonePrefix;

            if (oAuthParams !== null) {
                values["samlRequest"] = oAuthParams.samlRequest;
            }


            if (values["samlRequest"] != null && values["samlRequest"] !== "") {
                values["type"] = "saml";
            }
            AuthBackend.login(values, oAuthParams).then((res) => {
                console.log('4')
                console.log(values)
                if (res.status === 'ok') {
                    const responseType = values["type"];
                    if (responseType === "login") {
                        Util.showMessage("success", `Logged in successfully`);
                        console.log('5')
                        const link = Setting.getFromLink();
                        Setting.goToLink(link);
                    } else if (responseType === "code") {
                        const code = res.data;
                        const concatChar = oAuthParams?.redirectUri?.includes('?') ? '&' : '?';

                        if (Setting.hasPromptPage(application)) {
                            AuthBackend.getAccount("")
                                .then((res) => {
                                    let account = null;
                                    if (res.status === "ok") {
                                        account = res.data;
                                        account.organization = res.data2;
                                        console.log('6')
                                        this.onUpdateAccount(account);

                                        if (Setting.isPromptAnswered(account, application)) {
                                            Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
                                        } else {
                                            Setting.goToLinkSoft(ths, `/prompt/${application.name}?redirectUri=${oAuthParams.redirectUri}&code=${code}&state=${oAuthParams.state}`);
                                        }
                                    } else {
                                        Setting.showMessage("error", `Failed to sign in: ${res.msg}`);
                                    }
                                });
                        } else {
                            Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
                        }

                        // Util.showMessage("success", `Authorization code: ${res.data}`);
                    } else if (responseType === "token" || responseType === "id_token") {
                        const accessToken = res.data;
                        Setting.goToLink(`${oAuthParams.redirectUri}#${responseType}=${accessToken}?state=${oAuthParams.state}&token_type=bearer`);
                    } else if (responseType === "saml") {
                        const SAMLResponse = res.data;
                        const redirectUri = res.data2;
                        Setting.goToLink(`${redirectUri}?SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${oAuthParams.relayState}`);
                    }
                } else {
                    Util.showMessage("error", `Failed to log in: ${res.msg}`);
                }
            });
        }
    };

    getSigninButton(type) {
        console.log("6")
        const text = i18next.t("login:Sign in with {type}").replace("{type}", type);
        if (type === "GitHub") {
            return <GithubLoginButton text={text} align={"center"}/>
        } else if (type === "Google") {
            return <GoogleLoginButton text={text} align={"center"}/>
        } else if (type === "QQ") {
            return <QqLoginButton text={text} align={"center"}/>
        } else if (type === "Facebook") {
            return <FacebookLoginButton text={text} align={"center"}/>
        } else if (type === "Weibo") {
            return <WeiboLoginButton text={text} align={"center"}/>
        } else if (type === "Gitee") {
            return <GiteeLoginButton text={text} align={"center"}/>
        } else if (type === "WeChat") {
            return <WechatLoginButton text={text} align={"center"}/>
        } else if (type === "DingTalk") {
            return <DingTalkLoginButton text={text} align={"center"}/>
        } else if (type === "LinkedIn") {
            return <LinkedInLoginButton text={text} align={"center"}/>
        } else if (type === "WeCom") {
            return <WeComLoginButton text={text} align={"center"}/>
        } else if (type === "Lark") {
            return <LarkLoginButton text={text} align={"center"}/>
        } else if (type === "GitLab") {
            return <GitLabLoginButton text={text} align={"center"}/>
        } else if (type === "Adfs") {
            return <AdfsLoginButton text={text} align={"center"}/>
        } else if (type === "Casdoor") {
            return <CasdoorLoginButton text={text} align={"center"}/>
        } else if (type === "Baidu") {
            return <BaiduLoginButton text={text} align={"center"}/>
        } else if (type === "Alipay") {
            return <AlipayLoginButton text={text} align={"center"}/>
        } else if (type === "Infoflow") {
            return <InfoflowLoginButton text={text} align={"center"}/>
        } else if (type === "Apple") {
            return <AppleLoginButton text={text} align={"center"}/>
        } else if (type === "AzureAD") {
            return <AzureADLoginButton text={text} align={"center"}/>
        } else if (type === "Slack") {
            return <SlackLoginButton text={text} align={"center"}/>
        } else if (type === "Steam") {
            return <SteamLoginButton text={text} align={"center"}/>
        } else if (type === "Bilibili") {
            return <BilibiliLoginButton text={text} align={"center"}/>
        } else if (type === "Okta") {
            return <OktaLoginButton text={text} align={"center"}/>
        } else if (type === "Douyin") {
            return <DouyinLoginButton text={text} align={"center"}/>
        }

        return text;
    }

    getSamlUrl(provider) {
        const params = new URLSearchParams(this.props.location.search);
        let clientId = params.get("client_id");
        let application = params.get("state");
        let realRedirectUri = params.get("redirect_uri");
        let redirectUri = `${window.location.origin}/callback/saml`;
        let providerName = provider.name;
        let relayState = `${clientId}&${application}&${providerName}&${realRedirectUri}&${redirectUri}`;
        AuthBackend.getSamlLogin(`${provider.owner}/${providerName}`, btoa(relayState)).then((res) => {
            if (res.data2 === "POST") {
                document.write(res.data)
            } else {
                window.location.href = res.data
            }
        });
    }

    renderProviderLogo(provider, application, width, margin, size) {
        if (size === "small") {
            if (provider.category === "OAuth") {
                return (
                    <a style={{width: "100%"}} key={provider.displayName}
                       href={Provider.getAuthUrl(application, provider, "signup")}>
                        <div className="formBoxWx">
                            <img width={width} height={width} src={Setting.getProviderLogoURL(provider)}
                                 alt={provider.displayName} style={{margin: margin}}/>微信登录
                        </div>
                    </a>
                )
            } else if (provider.category === "SAML") {
                return (
                    <a key={provider.displayName} onClick={this.getSamlUrl.bind(this, provider)}>
                        <img width={width} height={width} src={Setting.getProviderLogoURL(provider)}
                             alt={provider.displayName} style={{margin: margin}}/>
                    </a>
                )
            }

        } else {
            return (
                <div key={provider.displayName} style={{marginBottom: "10px"}}>
                    <a href={Provider.getAuthUrl(application, provider, "signup")}>
                        {
                            this.getSigninButton(provider.type)
                        }
                    </a>
                </div>
            )
        }
    }

    isProviderVisible(providerItem) {
        if (this.state.mode === "signup") {
            return Setting.isProviderVisibleForSignUp(providerItem);
        } else {
            return Setting.isProviderVisibleForSignIn(providerItem);
        }
    }

    renderForm(application) {
        console.log(application)
        if (this.state.msg !== null) {
            return Util.renderMessage(this.state.msg)
        }

        if (this.state.mode === "signup" && !application.enableSignUp) {
            return (
                <Result
                    status="error"
                    title="Sign Up Error"
                    subTitle={"The application does not allow to sign up new account"}
                    extra={[
                        <Link onClick={() => {
                            Setting.goToLogin(this, application);
                        }}>
                            <Button type="primary" key="signin">
                                Sign In
                            </Button>
                        </Link>
                    ]}
                >
                </Result>
            )
        }

        if (application.enablePassword) {
            return (
                <Form
                    name="normal_login"
                    initialValues={{
                        organization: application.organization,
                        application: application.name,
                        autoSignin: true,
                    }}
                    onFinish={(values) => {
                        this.onFinish(values)
                    }}
                    style={{width: "300px", margin: "-50px auto"}}
                    size="large"
                >
                    <Form.Item
                        style={{height: 0, visibility: "hidden"}}
                        name="application"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your application!',
                            },
                        ]}
                    >
                    </Form.Item>
                    <Form.Item
                        style={{height: 0, visibility: "hidden"}}
                        name="organization"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your organization!',
                            },
                        ]}
                    >
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: i18next.t("login:Please input your username, Email or phone!")
                            },
                            {
                                validator: (_, value) => {
                                    if (this.state.isCodeSignin) {
                                        if (this.state.email !== "" && !Setting.isValidEmail(this.state.username) && !Setting.isValidPhone(this.state.username)) {
                                            this.setState({validEmailOrPhone: false});
                                            return Promise.reject(i18next.t("login:The input is not valid Email or Phone!"));
                                        }
                                    }
                                    if (Setting.isValidPhone(this.state.username)) {
                                        this.setState({validPhone: true})
                                    }
                                    if (Setting.isValidEmail(this.state.username)) {
                                        this.setState({validEmail: true})
                                    }
                                    this.setState({validEmailOrPhone: true});
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder={this.state.isCodeSignin ? i18next.t("login:Email or phone") : i18next.t("login:username, Email or phone")}
                            disabled={!application.enablePassword}
                            onChange={e => {
                                this.setState({
                                    username: e.target.value,
                                });
                            }}
                        />
                    </Form.Item>
                    {
                        this.state.isCodeSignin ? (
                            <Form.Item
                                name="password"
                                rules={[{required: true, message: i18next.t("login:Please input your password!")}]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    type="password"
                                    placeholder={i18next.t("login:Password")}
                                    disabled={!application.enablePassword}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="code"
                                rules={[{required: true, message: i18next.t("login:Please input your code!")}]}
                            >
                                <CountDownInput
                                    disabled={this.state.username?.length === 0 || !this.state.validEmailOrPhone}
                                    onButtonClickArgs={[this.state.username, this.state.validEmail ? "email" : "phone", Setting.getApplicationOrgName(application)]}
                                />
                            </Form.Item>
                        )
                    }
                    <Form.Item style={{display: 'none'}}>
                        <Form.Item name="autoSignin" valuePropName="checked" noStyle>
                            <Checkbox style={{float: "left", color: "white"}} disabled={!application.enablePassword}>
                                {i18next.t("login:Auto sign in")}
                            </Checkbox>
                        </Form.Item>
                        <a style={{float: "right"}} onClick={() => {
                            Setting.goToForget(this, application);
                        }}>
                            {i18next.t("login:Forgot password?")}
                        </a>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{width: "100%", marginBottom: '5px'}}
                            disabled={!application.enablePassword}
                        >
                            {i18next.t("login:Sign In")}
                        </Button>
                        {
                            !application.enableSignUp ? null : this.renderFooter(application)
                        }
                        <div style={{width: "100%", marginBottom: '5px'}} className="formBox">
                            <h4 style={{color: "while"}}>其他登录方式</h4>
                            {
                                application.providers.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {
                                    return this.renderProviderLogo(providerItem.provider, application, 30, 5, "small");
                                })
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        {/*{*/}
                        {/*  application.providers.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {*/}
                        {/*    return this.renderProviderLogo(providerItem.provider, application, 30, 5, "small");*/}
                        {/*  })*/}
                        {/*}*/}
                    </Form.Item>
                </Form>
            );
        } else {
            return (
                <div style={{marginTop: "20px"}}>
                    <div style={{fontSize: 16, textAlign: "left"}}>
                        {i18next.t("login:To access")}&nbsp;
                        <a target="_blank" rel="noreferrer" href={application.homepageUrl}>
              <span style={{fontWeight: "bold"}}>
                {application.displayName}
              </span>
                        </a>
                        :
                    </div>
                    <br/>
                    {
                        application.providers.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {
                            return this.renderProviderLogo(providerItem.provider, application, 40, 10, "big");
                        })
                    }
                    {
                        !application.enableSignUp ? null : (
                            <div>
                                <br/>
                                {
                                    this.renderFooter(application)
                                }
                            </div>
                        )
                    }
                </div>
            )
        }
    }

    renderFooter(application) {
        if (this.state.mode === "signup") {
            return (
                <div style={{float: "right"}}>
                    {i18next.t("signup:Have account?")}&nbsp;
                    <Link onClick={() => {
                        Setting.goToLogin(this, application);
                    }}>
                        {i18next.t("signup:sign in now")}
                    </Link>
                </div>
            )
        } else {
            return (
                <React.Fragment>
          <span style={{float: "left"}}>
            {
                !application.enableCodeSignin ? null : (
                    <a onClick={() => {
                        this.setState({
                            isCodeSignin: !this.state.isCodeSignin,
                        });
                    }}>
                        {this.state.isCodeSignin ? '': ''}
                    </a>
                )
            }
          </span>
          {/*          <span style={{float: "right", color: "white"}}>*/}
          {/*  {i18next.t("login:No account?")}&nbsp;*/}
          {/*              <a onClick={() => {*/}
          {/*                  sessionStorage.setItem("loginURL", window.location.href)*/}
          {/*                  Setting.goToSignup(this, application);*/}
          {/*              }}>*/}
          {/*    {i18next.t("login:sign up now")}*/}
          {/*  </a>*/}
          {/*</span>*/}
                    <span  style={{float: "left",cursor:'pointer'}} onClick={() => {
                       this.show()
                    }}><a style={{textDecorationLine:'underline',color:"white"}} href="JavaScript:void(0)">{this.state.isCodeSignin==true?'验证码登录':'账号密码登录'}</a></span>

                </React.Fragment>
            )
        }
    }
    show(){
        if (this.state.isCodeSignin==false){
            this.setState({
                isCodeSignin:true
            })
        }else {
            this.setState({
                isCodeSignin:false
            })
        }
    }
    renderSignedInBox() {
        if (this.props.account === undefined || this.props.account === null) {
            return null;
        }
        let application = this.getApplicationObj()
        if (this.props.account.owner !== application.organization) {
            return null;
        }

        const params = new URLSearchParams(this.props.location.search);
        let silentSignin = params.get("silentSignin");
        if (silentSignin !== null) {
            if (window !== window.parent) {
                const message = {tag: "Casdoor", type: "SilentSignin", data: "signing-in"};
                window.parent.postMessage(message, "*");
            }

            let values = {};
            values["application"] = this.state.application.name;
            this.onFinish(values);
        }

        if (this.getCookie("bing-phone") === "00") {
            return (
                <div>
                    {/*{*/}
                    {/*  JSON.stringify(silentSignin)*/}
                    {/*}*/}
                    <div style={{fontSize: 16, textAlign: "left"}}>
                        {i18next.t("login:Continue with")}&nbsp;:
                    </div>
                    <br/>
                    <SelfLoginButton id="kc" account={this.props.account} onClick={() => {
                        let values = {};
                        values["application"] = this.state.application.name;
                        this.onFinish(values);
                    }}/>
                    <br/>
                    <br/>
                    <div style={{fontSize: 16, textAlign: "left"}}>
                        {i18next.t("login:Or sign in with another account")}&nbsp;:
                    </div>
                </div>
            )
        }
    }

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    //解码
    decode(bytes) {
        let escaped = escape(atob(bytes || ""));
        try {
            return decodeURIComponent(escaped);
        } catch {
            return unescape(escaped);
        }
    }
    render() {
        const application = this.getApplicationObj();
        console.log(application)
        if (application === null) {
            return Util.renderMessageLarge(this, this.state.msg);
        }

        if (application.signinHtml !== "") {
            return (
                <div dangerouslySetInnerHTML={{__html: application.signinHtml}}/>
            )
        }

        const visibleOAuthProviderItems = application.providers.filter(providerItem => this.isProviderVisible(providerItem));
        if (this.props.application === undefined && !application.enablePassword && visibleOAuthProviderItems.length === 1) {
            Setting.goToLink(Provider.getAuthUrl(application, visibleOAuthProviderItems[0].provider, "signup"));
            return (
                <div style={{textAlign: "center"}}>
                    <Spin size="large" tip={i18next.t("login:Signing in...")} style={{paddingTop: "10%"}}/>
                </div>
            )
        }

        if (this.getCookie('bing-phone') === "00") {
            return (
                <Row>
                    <Spin
                        className='my-loading-container'
                        tip='Loading...'
                        style={{
                            backgroundColor: 'rgba(1,1,1,1)',
                            minHeight: '100vh',
                            minWidth: '100%',
                            position: 'fixed',
                            zIndex: 10000
                        }}
                    >
                    </Spin>
                    <div className='loginG'>派派 AI 登录</div>
                    <Col span={24} style={{display: "flex", justifyContent: "space-around", overflow: "hidden"}}>
                        <div className="content-left"></div>
                        <div className="Back" style={{textAlign: "center"}}>
                            {
                                Setting.renderHelmet(application)
                            }
                            <CustomGithubCorner/>
                            <div className='login-pf-header'>
                                <h1 id="kc-page-title">PYPYAI.COM</h1>
                                <h3 id="kc-page-title">让世界充满AI</h3>
                            </div>
                            {/*{*/}
                            {/*  Setting.renderLogo(application)*/}
                            {/*}*/}
                            {/*{*/}
                            {/*  this.state.clientId !== null ? "Redirect" : null*/}
                            {/*}*/}
                            {
                                this.renderSignedInBox()
                            }
                            {
                                this.renderForm(application)
                            }
                        </div>
                    </Col>
                </Row>
            )
        }

        return (
            <Row>
                <div className='loginG'>派派 AI 登录</div>
                <Col span={24} style={{display: "flex", justifyContent: "space-around", overflow: "hidden"}}>
                    <div className="content-left"></div>
                    <div className="Back" style={{textAlign: "center"}}>
                        {
                            Setting.renderHelmet(application)
                        }
                        <CustomGithubCorner/>
                        <div className='login-pf-header'>
                            <h1 id="kc-page-title">PYPYAI.COM</h1>
                            <h3 id="kc-page-title">让世界充满AI</h3>
                        </div>
                        {/*{*/}
                        {/*  Setting.renderLogo(application)*/}
                        {/*}*/}
                        {/*{*/}
                        {/*  this.state.clientId !== null ? "Redirect" : null*/}
                        {/*}*/}
                        {
                            this.renderSignedInBox()
                        }
                        {
                            this.renderForm(application)
                        }
                    </div>
                </Col>
            </Row>
        )
    }
}

export default LoginPage;
